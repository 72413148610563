<template>
  <div class="contact-form w-full">
  </div>
</template>

<script>
export default {
  name: 'ContactForm',
  data: () => {
	return {
	}
  },
  methods: {
  },
  props: [],
  setup() {
	return {
	}
  },
}
</script>
<style scoped>
</style>
